import styled from "styled-components";
import React, { Component } from "react";
import "./footer.css";
import { NavLink } from "react-router-dom";

const FooterContainer = styled.div`
  margin: 0 auto;
  box-shadow: 0px 1px 0px 1px rgba(219, 216, 219, 1);
  width: 100%;
  padding: 2rem 0;
  background-color: #111;
  text-align: center;
  height: 17rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 1200px) and (min-width: 300px) {
    height: 13rem;
    padding: 0;
  }
`;
const Logo = styled.img`
  cursor: pointer;
  height: 5rem;
  @media (max-width: 768px) and (min-width: 300px) {
    height: 3rem;
  }
  @media (max-width: 1200px) and (min-width: 769px) {
  }
`;
const MenuFooter = styled.div`
  margin: 0 auto;
  box-shadow: 0px 1px 0px 1px rgba(219, 216, 219, 1);
  width: 100%;
  color: #fff;
  text-align: center;
  height: 13rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 768px) and (min-width: 300px) {
  }
  @media (max-width: 1200px) and (min-width: 769px) {
  }
`;
const MenuFooterLinks = styled.span`
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 700;
  color: #fff;
  @media (max-width: 768px) and (min-width: 300px) {
    font-size: 1rem;
  }
  @media (max-width: 1200px) and (min-width: 769px) {
  }
`;
const CopyRight = styled.span`
  cursor: pointer;
  font-size: 0.7rem;
  color: #fff;
  padding: 0.5rem 0;
  font-weight: 300;
  @media (max-width: 768px) and (min-width: 300px) {
  }
  @media (max-width: 1200px) and (min-width: 769px) {
  }
`;

export class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = { countryModal: false, LangModal: false };
    // this.deleting = this.deleting.bind(this);
  }

  showSettings(event) {
    event.preventDefault();
  }

  render() {
    return (
      <FooterContainer>
        <Logo src={require("../../../assets/main-logo.png")} />
        <MenuFooter>
          <NavLink to="/tvätt">
            <MenuFooterLinks>Tvätt</MenuFooterLinks>
          </NavLink>
          <NavLink to="/lagning">
            <MenuFooterLinks>Lagning</MenuFooterLinks>
          </NavLink>
          <NavLink to="/portfölj">
            <MenuFooterLinks>Portfölj</MenuFooterLinks>
          </NavLink>
          <NavLink to="/kontakt">
            <MenuFooterLinks>Kontakt</MenuFooterLinks>
          </NavLink>
        </MenuFooter>
        <CopyRight>
          {" "}
          2022 &#x24B8; Mattservicen Stockholm AB, All Rights Reserved
        </CopyRight>
      </FooterContainer>
    );
  }
}

export default Footer;
