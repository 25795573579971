import React, { Component } from "react";
import TopContainer from "../Top/topContainer";
import styled from "styled-components";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import "./portfölj.css";
import Footer from "../Footer/footer";
import { Helmet } from "react-helmet";
import Carousel, { Modal, ModalGateway } from "react-images";

const Container = styled.div`
  width: 100%;
  height: auto;
  background-color: #eff0f1;
  padding-bottom: 6rem;
  @media (max-width: 768px) {
    padding-bottom: 5rem;
  }
`;
const Gallery = styled.div`
overflow: hidden,
display:flex;
flex-direction:row;
@media (max-width: 768px) {
  display:flex;
  flex-direction:column;
  align-items:center;
}
`;
const Img = styled.img`
  height: 15rem;
  margin: 2rem 1rem;
  cursor: pointer;
  transition: transform 0.3s;
  @media (max-width: 768px) {
    width: 90%;
    height: auto;
    margin: 1rem 0rem;
  }
  &:hover {
    transform: scale(1.06);
  }
`;
const Title = styled.h3`
  margin-top: 4rem;
  @media (max-width: 768px) {
    margin-top: 2rem;
  }
`;

export class Portfölj extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    modal1IsOpen: false,
    modal2IsOpen: false,
    modal3IsOpen: false,
    modal4IsOpen: false,
    modal5IsOpen: false,
    modal6IsOpen: false,
  };
  toggleModal1 = () => {
    this.setState((state) => ({ modal1IsOpen: !state.modal1IsOpen }));
  };
  toggleModal2 = () => {
    this.setState((state) => ({ modal2IsOpen: !state.modal2IsOpen }));
  };
  toggleModal3 = () => {
    this.setState((state) => ({ modal3IsOpen: !state.modal3IsOpen }));
  };
  toggleModal4 = () => {
    this.setState((state) => ({ modal4IsOpen: !state.modal4IsOpen }));
  };
  toggleModal5 = () => {
    this.setState((state) => ({ moda5IsOpen: !state.modal5IsOpen }));
  };

  render() {
    const { modal1IsOpen } = this.state;
    const { modal2IsOpen } = this.state;
    const { modal3IsOpen } = this.state;
    const { modal4IsOpen } = this.state;
    const { modal5IsOpen } = this.state;

    const group1 = [
      { source: require("../../../assets/portfölj/1.jpg") },
      { source: require("../../../assets/portfölj/2.jpg") },
      { source: require("../../../assets/portfölj/3.jpg") },
      { source: require("../../../assets/portfölj/4.jpg") },
    ];
    const group2 = [
      { source: require("../../../assets/portfölj/5.jpg") },
      { source: require("../../../assets/portfölj/6.jpg") },
      { source: require("../../../assets/portfölj/7.jpg") },
    ];
    const group3 = [
      { source: require("../../../assets/portfölj/8.jpg") },
      { source: require("../../../assets/portfölj/9.jpg") },
      { source: require("../../../assets/portfölj/10.jpg") },
      { source: require("../../../assets/portfölj/11.jpg") },
    ];
    const group4 = [
      { source: require("../../../assets/portfölj/12.jpg") },
      { source: require("../../../assets/portfölj/13.jpg") },
      { source: require("../../../assets/portfölj/14.jpg") },
      { source: require("../../../assets/portfölj/15.jpg") },
      { source: require("../../../assets/portfölj/16.jpg") },
    ];
    const group5 = [
      { source: require("../../../assets/portfölj/17.jpg") },
      { source: require("../../../assets/portfölj/18.jpg") },
      { source: require("../../../assets/portfölj/19.jpg") },
    ];

    return (
      <Container>
        <Helmet
          title={
            "Mattservicen - mattvätt stockholm , mattlagning stockholm , mattrengörning , märta måås lagnong - portfölj"
          }
        >
          <meta name="MattServicen" content="MattServicen" />
        </Helmet>
        <TopContainer />
        {/* <DztImageGalleryComponent 
          images={data} /> */}
        <Title>
          1- Rölakan matta-AB MMF (AB Märtta Måås-Fjettersröm-Barbro Nilsson)
          1950 talets
        </Title>
        <Gallery>
          {group1.map(({ author, caption, source }, j) => (
            <Img
              onClick={() => this.toggleModal1(j)}
              key={source}
              alt={caption}
              src={source}
            />
          ))}
        </Gallery>
        <Title>2- Röllakan matta-AÖ (Agda Österberg) 1950 talets</Title>
        <Gallery>
          {group2.map(({ author, caption, source }, j) => (
            <Img
              onClick={() => this.toggleModal2(j)}
              key={source}
              alt={caption}
              src={source}
            />
          ))}
        </Gallery>
        <Title>3- Antik kirman-persisk matta-(1900 talets)</Title>
        <Gallery>
          {group3.map(({ author, caption, source }, j) => (
            <Img
              onClick={() => this.toggleModal3(j)}
              key={source}
              alt={caption}
              src={source}
            />
          ))}
        </Gallery>
        <Title>
          4- Rölakan matta-AB MMF (AB Märtta Måås-Fjetterström-Barbro Nilsson)
          1950 talets
        </Title>
        <Gallery>
          {group4.map(({ author, caption, source }, j) => (
            <Img
              onClick={() => this.toggleModal4(j)}
              key={source}
              alt={caption}
              src={source}
            />
          ))}
        </Gallery>
        <Title>
          5- Rölakan matta-AB MMF (AB Märtta Måås-Fjetterström) 1940 talets
        </Title>
        <Gallery>
          {group5.map(({ author, caption, source }, j) => (
            <Img
              onClick={() => this.toggleModal5(j)}
              key={source}
              alt={caption}
              src={source}
            />
          ))}
        </Gallery>

        <ModalGateway>
          {modal1IsOpen ? (
            <Modal onClose={this.toggleModal1}>
              <Carousel views={group1} />
            </Modal>
          ) : null}
        </ModalGateway>
        <ModalGateway>
          {modal2IsOpen ? (
            <Modal onClose={this.toggleModal2}>
              <Carousel views={group2} />
            </Modal>
          ) : null}
        </ModalGateway>
        <ModalGateway>
          {modal3IsOpen ? (
            <Modal onClose={this.toggleModal3}>
              <Carousel views={group3} />
            </Modal>
          ) : null}
        </ModalGateway>
        <ModalGateway>
          {modal4IsOpen ? (
            <Modal onClose={this.toggleModal4}>
              <Carousel views={group4} />
            </Modal>
          ) : null}
        </ModalGateway>
        <ModalGateway>
          {modal5IsOpen ? (
            <Modal onClose={this.toggleModal5}>
              <Carousel views={group5} />
            </Modal>
          ) : null}
        </ModalGateway>

        <Footer></Footer>
      </Container>
    );
  }
}

export default Portfölj;
