"use strict";
import React, { Component } from "react";
import ReactPlayer from "react-player";
import "react-html5video/dist/styles.css";
import styled from "styled-components";
import TopContainer from "../Top/topContainer";
import Footer from "../Footer/footer";
import "./lagning.css";
import { Helmet } from "react-helmet";
const TopLine = styled.div`
  width: 100%;
  background-color: #888;
  height: 4px;
  @media (max-width: 768px) and (min-width: 300px) {
    height: 3px;
  }
`;
const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem;
  align-content: center;
  text-align: center;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) and (min-width: 300px) {
    padding: 1rem;
  }
  @media (max-width: 1100px) and (min-width: 769px) {
    padding: 1rem;
  }
`;

const TitleTxt = styled.h1`
  font-size: 1.7rem;
  text-align: left;
  font-weight: 900;
  letter-spacing: 0.05em;
  line-height: 35px;
  margin: 1rem 0;
  @media (max-width: 768px) and (min-width: 300px) {
    font-size: 1.2rem;
  }
  @media (max-width: 1100px) and (min-width: 769px) {
    font-size: 1.4rem;
  }
`;
const BodyTxt = styled.p`
  font-size: 1.1rem;
  text-align: left;
  letter-spacing: 0.05em;
  line-height: 30px;
  @media (max-width: 768px) and (min-width: 300px) {
    font-size: 1rem;
  }
  @media (max-width: 1100px) and (min-width: 769px) {
    font-size: 1.15rem;
  }
`;
const BodyTxtInnerTwoColumns = styled.p`
  font-size: 1.1rem;
  text-align: left;
  margin: 0 1rem;
  letter-spacing: 0.05em;
  line-height: 25px;
  @media (max-width: 768px) and (min-width: 300px) {
    font-size: 1rem;
  }
  @media (max-width: 1100px) and (min-width: 769px) {
    font-size: 1.15rem;
  }
`;
const TwoColumns = styled.div`
  width: 100%;
  height: auto;
  margin: 3rem 0rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) and (min-width: 300px) {
    flex-direction: column;
    margin: 1rem 0;
  }
  @media (max-width: 1100px) and (min-width: 769px) {
    margin: 1rem 0;
  }
`;
const InnerTwoColumns = styled.div`
  width: 100%;
  height: auto;
  margin: 1rem 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) and (min-width: 300px) {
    flex-direction: column;
    margin: 1rem 0;
  }
  @media (max-width: 1100px) and (min-width: 769px) {
    margin: 1rem 0;
  }
`;
const ImgDiv = styled.div`
  width: 50%;
  background-color: #fff;
  height: auto;
  padding: 2rem;
  max-height: 25rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) and (min-width: 300px) {
    width: 95%;
    max-height: 17rem;
  }
  @media (max-width: 1100px) and (min-width: 769px) {
    width: 95%;
    max-height: 17rem;
  }
`;
const Img = styled.img`
  width: 100%;
  height: auto;

  @media (max-width: 768px) and (min-width: 300px) {
  }
`;
const TextDiv = styled.div`
  width: 50%;
  height: auto;
  padding: 0 8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: 768px) and (min-width: 300px) {
    padding: 0 0.5rem;
    width: 95%;
  }
  @media (max-width: 1100px) and (min-width: 769px) {
    width: 95%;
    padding: 0 0.5rem;
  }
`;

const Lagning = () => {
  return (
    <div className="lagningBg">
      <Helmet
        title={
          "Mattservicen - mattvätt, mattlagning stockholm , mattkantlagning, mattlängettering, mattfrans lagning"
        }
      >
        <meta name="MattServicen" content="MattServicen" />
      </Helmet>
      <TopLine></TopLine>
      <TopContainer />
      <ReactPlayer
        url="https://www.youtube.com/watch?v=IoxwUPEH-fE&feature=youtu.be"
        width="100%"
        height="24rem"
        controls={false}
        muted
        playing
      ></ReactPlayer>
      <div className="filtering"></div>
      <Section>
        <TitleTxt>Reparation</TitleTxt>
        <TwoColumns>
          <TextDiv>
            <BodyTxt>
              Alla mattor slits men kan normalt lagas. Vi är specialister på att
              laga mattor, såväl moderna som antika.
            </BodyTxt>
            <InnerTwoColumns>
              <BodyTxtInnerTwoColumns>
                Mattfransar bör inte dammsugas så ofta, eftersom fransarna kan
                skadas. Om skador skett, oavsett hur, kan vi åtgärda detta.
              </BodyTxtInnerTwoColumns>
              <BodyTxtInnerTwoColumns>
                Stadkanter slits ofta ut först. Vi reparerar och byter ut dessa
                enkelt och mattan bli som ny igen!
              </BodyTxtInnerTwoColumns>
            </InnerTwoColumns>
            <BodyTxt>
              Du kan tryggt lämna in din matta på reparation till oss. Vi
              garanterar ett gott resultat efter lagningen.
            </BodyTxt>
          </TextDiv>
          <ImgDiv>
            <BodyTxt>FÖRE</BodyTxt>
            <Img src={require("../../../assets/lag-02-before.jpeg")}></Img>
          </ImgDiv>
        </TwoColumns>
      </Section>
      <Section>
        <TwoColumns>
          <ImgDiv>
            <BodyTxt>EFTER</BodyTxt>
            <Img src={require("../../../assets/lag01-after.jpeg")}></Img>
          </ImgDiv>
          <TextDiv>
            <TitleTxt>Antika, äkta mattor</TitleTxt>
            <BodyTxt>
              Vi underhåller turkiska, persiska, afghanska och kinesiska mattor
              samt övriga så kallade orientaliska mattor och väv.
            </BodyTxt>
          </TextDiv>
        </TwoColumns>
      </Section>
      <Section>
        <TwoColumns>
          <TextDiv>
            <BodyTxt>
              Det finns nygjorda mattor som ser ut som antika. Vi lagar det som
              är slitet eller söndrigt. Vi älskar vårt arbete.
            </BodyTxt>
            <BodyTxt>
              Tibetanska, egyptiska pakistanska eller annat ursprung spelar
              ingen roll. Alla slitna mattor behöver vård och någon som ser över
              dem ibland. Vi åtar oss gärna detta.
            </BodyTxt>
            <BodyTxt>
              En del skador kan förebyggas, genom placering. Andra mattskador
              kan förbyggas genom insektsfällor. Att ta bort grus och sand
              hjälper mattan mycket!
            </BodyTxt>
          </TextDiv>

          <ImgDiv>
            <BodyTxt>FÖRE</BodyTxt>
            <Img src={require("../../../assets/lagningforeefter3.jpeg")}></Img>
          </ImgDiv>
        </TwoColumns>
      </Section>

      <Section>
        <TitleTxt>Textilstrukturer</TitleTxt>
        <TwoColumns>
          <ImgDiv>
            <BodyTxt>EFTER</BodyTxt>
            <Img src={require("../../../assets/lagningforeefter4.jpeg")}></Img>
          </ImgDiv>
          <TextDiv>
            <BodyTxt>
              Vi har arbetat med mattor i generationer. Mattors motiv,
              färgnyanser och struktur är vårt liv.
            </BodyTxt>
            <InnerTwoColumns>
              <BodyTxtInnerTwoColumns>
                Många drar sig för att laga sin fina matta av rädsla att den kan
                bli lagad på ett oriktigt sätt. Lita på oss, vi kan vår sak!
              </BodyTxtInnerTwoColumns>
              <BodyTxtInnerTwoColumns>
                Vi gläds åt att se nya mattor - den ena är inte lik den andra.
                Vissa ger uttryck för gamla beprövade idéer medan andra har
                fräscha nya sådana.
              </BodyTxtInnerTwoColumns>
            </InnerTwoColumns>
            <BodyTxt>
              Vi finner mycket nöje i vårt arbete och att få se så många
              varierande idéer! Att sköta om andras mattor är något som vi gärna
              ägnar åt oss.
            </BodyTxt>
          </TextDiv>
        </TwoColumns>
      </Section>
      <Footer></Footer>
    </div>
  );
};

export default Lagning;
