import styled from "styled-components";
import "./top.css";
import { NavLink } from "react-router-dom";
import { Colors, Sizes } from "../../Constants/Constants";
import useIsMobile from "../../hooks/useWindowSize";
import BurgerMenu from "./BurgerMenu";

const HeaderContainer = styled.div`
  margin: 0 auto;
  box-shadow: 0px 1px 0px 1px rgba(219, 216, 219, 1);
  width: 100%;
  background-color: #eff0f1;
  text-align: center;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 1200px) and (min-width: 300px) {
    flex-direction: row;
    height: 45px;
    justify-content: center;
  }
`;
const Left = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 30px;
  align-items: center;
  width: 50%;
  @media (max-width: 768px) and (min-width: 300px) {
    width: 100%;
    flex-direction: row-reverse;
    margin: 0;
    padding: 0;
    justify-content: center;
  }
  @media (max-width: 1200px) and (min-width: 769px) {
    width: 40%;
  }
`;
const Right = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 50%;
  @media (max-width: 768px) and (min-width: 300px) {
    width: 45%;
    display: none;
  }
  @media (max-width: 1500px) and (min-width: 769px) {
    width: 60%;
  }
`;
const Logo = styled.img`
  cursor: pointer;
  height: 6rem;
  @media (max-width: 768px) and (min-width: 300px) {
    height: 4rem;
  }
  @media (max-width: 1200px) and (min-width: 769px) {
    height: 4rem;
  }
`;

const MenuText = styled.span`
  font-size: ${Sizes.XSmall};
  margin: ${Sizes.XXSmall};
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.3s;
  display: inline-block;

  &:hover::after {
    width: 100%;
  }
  &:after{
    content: '';
    width: 0px;
    height: 1px;
    display: block;
    background: ${Colors.LightBlue};
    transition: 300ms;
  }
  }
`;

const ImgLogo = styled.img`
  height: 20px;
  margin-left: 3px;
  @media (max-width: 768px) and (min-width: 300px) {
    height: 14px;
  }
`;

const TopContainerNorway = (props) => {
  const isMobile = useIsMobile();
  return (
    <HeaderContainer>
      {isMobile ? (
        <>
          <BurgerMenu />
          <Left>
            <NavLink to="/">
              <Logo
                alt="matservicen"
                src={require("../../../assets/main-logo.png")}
              />
            </NavLink>
          </Left>
        </>
      ) : (
        <>
          <Left>
            <NavLink to="/">
              <Logo
                alt="matservicen"
                src={require("../../../assets/main-logo.png")}
              />
            </NavLink>
          </Left>
          <Right>
            <NavLink
              style={({ isActive }) =>
                isActive
                  ? { color: `${Colors.LightBlue}` }
                  : { color: `${Colors.Black}` }
              }
              to="/kontakt"
            >
              <MenuText>Kontakt</MenuText>
            </NavLink>

            <NavLink
              style={({ isActive }) =>
                isActive
                  ? { color: `${Colors.LightBlue}` }
                  : { color: `${Colors.Black}` }
              }
              to="/portfölj"
            >
              <MenuText>Portfölj</MenuText>
            </NavLink>

            <NavLink
              style={({ isActive }) =>
                isActive
                  ? { color: `${Colors.LightBlue}` }
                  : { color: `${Colors.Black}` }
              }
              to="/lagning"
            >
              <MenuText>Lagning</MenuText>
            </NavLink>

            <NavLink
              style={({ isActive }) =>
                isActive
                  ? { color: `${Colors.LightBlue}` }
                  : { color: `${Colors.Black}` }
              }
              to="/tvatt"
            >
              <MenuText>Tvätt</MenuText>
            </NavLink>

            <NavLink to="/">
              <ImgLogo src="https://firebasestorage.googleapis.com/v0/b/nordicbazar-76aa7.appspot.com/o/Icons%2Fhome-7-128.png?alt=media&token=1f95378d-43f5-48e3-b06a-c1be87737908" />
            </NavLink>
          </Right>
        </>
      )}
    </HeaderContainer>
  );
};

export default TopContainerNorway;
