import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Kontakt from "./Components/PAGES/Contactus/contactus";
import HomePage from "./homepage";
import Lagning from "./Components/PAGES/Lagning/lagning";
import Tvätt from "./Components/PAGES/Tvätt/tvätt";
import Portfölj from "./Components/PAGES/Portfölj/portfölj";

import "./App.css";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        {/* <ScrollToTop /> */}
        <Routes>
          <Route path="/" element={<HomePage />}></Route>
          <Route path="/portfölj" element={<Portfölj />}></Route>
          <Route path="/kontakt" element={<Kontakt />}></Route>
          <Route path="/lagning" element={<Lagning />}></Route>
          <Route path="/tvatt" element={<Tvätt />}></Route>

          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>Page Not Found!</p>
              </main>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
