import ReactPlayer from "react-player";
import "react-html5video/dist/styles.css";
import styled from "styled-components";
import TopContainer from "../Top/topContainer";
import { Helmet } from "react-helmet";
import Footer from "../Footer/footer";

const TopLine = styled.div`
  width: 100%;
  background-color: #888;
  height: 4px;
  @media (max-width: 768px) and (min-width: 300px) {
    height: 3px;
  }
`;
const VideoTitle = styled.h1`
  font-size: 2.6rem;
  color: #000;
  font-weight: 900;
  letter-spacing: 4px;
  margin-top: 1rem;
  @media (max-width: 768px) and (min-width: 300px) {
    margin-top: 1rem;
    font-size: 1.4rem;
  }
  @media (max-width: 1100px) and (min-width: 769px) {
    font-size: 1.7rem;
    margin-top: 1rem;
  }
`;
const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem;
  align-content: center;
  text-align: center;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) and (min-width: 300px) {
    padding: 1rem;
  }
  @media (max-width: 1100px) and (min-width: 769px) {
    padding: 1rem;
  }
`;
const TitleTxt = styled.h3`
  font-size: 1.7rem;
  text-align: left;
  font-weight: 900;
  margin: 1rem 0;
  letter-spacing: 0.05em;
  line-height: 30px;
  @media (max-width: 768px) and (min-width: 300px) {
    font-size: 1.2rem;
  }
  @media (max-width: 1100px) and (min-width: 769px) {
    font-size: 1.4rem;
  }
`;
const BodyTxt = styled.p`
  font-size: 1.1rem;
  text-align: left;
  letter-spacing: 0.05em;
  line-height: 28px;
  @media (max-width: 768px) and (min-width: 300px) {
    font-size: 0.8rem;
  }
  @media (max-width: 1100px) and (min-width: 769px) {
    font-size: 1rem;
  }
`;
const TwoColumns = styled.div`
  width: 100%;
  height: auto;
  margin: 3rem 0rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  @media (max-width: 768px) and (min-width: 300px) {
    flex-direction: column;
    margin: 1rem 0;
  }
  @media (max-width: 1100px) and (min-width: 769px) {
    margin: 1rem 0;
  }
`;
const ImgDiv = styled.div`
  width: 25%;
  background-color: #fff;
  height: auto;
  padding: 2rem;
  max-height: 16rem;
  overflow: hidden;
  align-items: center;
  @media (max-width: 768px) and (min-width: 300px) {
    width: 95%;
  }
  @media (max-width: 1100px) and (min-width: 769px) {
    width: 95%;
  }
`;
const Img = styled.img`
  width: 100%;
  height: auto;

  @media (max-width: 768px) and (min-width: 300px) {
  }
`;
const TextDiv = styled.div`
  width: 50%;
  height: auto;
  padding: 0 8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: 768px) and (min-width: 300px) {
    padding: 0 1rem;
    width: 95%;
  }
  @media (max-width: 1100px) and (min-width: 769px) {
    padding: 0 1rem;
    width: 95%;
  }
`;
const TitleArbete = styled.span`
  font-size: 1.35rem;
  text-align: left;
  margin: 1rem 0;
  font-weight: 900;
  letter-spacing: 0.05em;
  line-height: 25px;
  @media (max-width: 768px) and (min-width: 300px) {
    text-align: center;
    font-size: 1.15rem;
  }
  @media (max-width: 1100px) and (min-width: 769px) {
    text-align: center;
    font-size: 1.2rem;
  }
`;

const BodyArbeteTxt = styled.span`
  font-size: 1rem;
  text-align: left;
  letter-spacing: 0.05em;
  line-height: 25px;
  @media (max-width: 768px) and (min-width: 300px) {
    font-size: 1rem;
  }
  @media (max-width: 1100px) and (min-width: 769px) {
    font-size: 1.1rem;
  }
`;

const Tvätt = () => {
  return (
    <div className="tvättBg">
      <Helmet
        title={
          "Mattservicen - mattvätt stockholm , mattlagning stockholm , mattrengörning , märta måås lagnong"
        }
      >
        <meta name="MattServicen" content="MattServicen" />
      </Helmet>

      <TopLine></TopLine>
      <TopContainer />
      <ReactPlayer
        url="https://www.youtube.com/watch?v=IldqAqb93tk&feature=emb_logo"
        width="100%"
        height="24rem"
        controls={false}
        muted
        playing
      ></ReactPlayer>
      <Section>
        <VideoTitle>Överlåt tvätten av mattan till oss!</VideoTitle>
        <BodyTxt>
          Alla kan ha otur att få en fläck på mattan. Det är viktigt att inte
          förvärra fläcken genom att - som många som dessvärre gör - att gnugga
          in fläcken i mattan.
        </BodyTxt>
        <BodyTxt>
          Ring oss gärna om olyckan varit framme och du fått en fläck på mattan.
          Tänk på att rengöringsmedel ofta gör större skada än nytta. Blekmedel
          är absolut fel att använda sig av. Hör med oss istället! Vi ger dig
          gärna råd.e
        </BodyTxt>
        <TwoColumns>
          <ImgDiv>
            <Img src={require("../../../assets/tvätt11.jpeg")}></Img>
          </ImgDiv>
          <ImgDiv>
            <Img src={require("../../../assets/tvätt12.jpeg")}></Img>
          </ImgDiv>
        </TwoColumns>
        <BodyTxt>
          Om olyckan skulle vara framme och du får en vätskefläck, så skrapa
          bort vätskan! Använd en sked eller ett knivblad eller något liknande
          för att ta upp vätskan - alltid i luggens rikting!
        </BodyTxt>
        <BodyTxt>
          Försök att ta upp vätska som är kvar med en fuktig trasa. Skölj trasan
          och upprepa igen
        </BodyTxt>
        <BodyTxt>
          Om mattan luggar av sig efter rengöringen beror det på att tidigare
          överskottsull släpper. Mattan är inte skadad!
        </BodyTxt>
      </Section>
      <TwoColumns>
        <TextDiv>
          <TitleArbete>Röllakan</TitleArbete>
          <BodyArbeteTxt>
            Röllakansmattor tål vatten i de flesta fall. Att använda tvättmaskin
            är olämpligt. eftersom vikveck blir en följd av maskintvätten. Du
            kan testa att tvätta mattan under rinnande vatten med en borste samt
            milt tvättmedel. Men skölj noga och torka mattan snabbt så att det
            inte blir fuktskador. Givetvis är det säkrast att vi tvättar mattan
            åt dig.
          </BodyArbeteTxt>
        </TextDiv>
        <TextDiv>
          <TitleArbete>Rya- och ullmattor</TitleArbete>
          <BodyArbeteTxt>
            Handgjorda mattor bör man alltid lämna till en professionell
            mattvätt. Även handtuftade mattor bör tvättas professionellt. Om du
            ändå tvättar själv bör du vara försiktig med fläckborttagningsmedel
            för att undvika permanenta skador!
          </BodyArbeteTxt>
        </TextDiv>
      </TwoColumns>
      <TwoColumns>
        <TextDiv>
          <TitleArbete>Orientmattor</TitleArbete>
          <BodyArbeteTxt>
            Orientmattor bör du alltid lämna till en professionellt mattvätt.
            Det beror på att färgerna kan vara känsliga för fällning och på
            vissa mattor kan varpen krympa. Du måste vara mycket försiktig om du
            ska försöka att ta bort fläckar själv. Både vatten och
            rengöringsmedel kan medföra missfärgningar och orsaka permanenta
            skador.
          </BodyArbeteTxt>
        </TextDiv>
        <TextDiv>
          <TitleArbete>Maskingjorda mattor</TitleArbete>
          <BodyArbeteTxt>
            Följ alltid tvättanvisningen på mattans baksida.De flesta sådana
            mattor tål vatten bra, men en del ska kemtvättas. Det senare kan
            bero på att mattan har vattenkänslig färger eller på grund av att
            appreturen i mattans baksida annars riskerar att försvagas så att
            mattan blir mjuk och sladdrig. Det är enklast och bäst att lämna
            mattan till oss för tvätt.
          </BodyArbeteTxt>
        </TextDiv>
      </TwoColumns>
      <Section>
        <TitleTxt>Fläckborttagning</TitleTxt>
        <BodyTxt>
          Vi tar bort fläckar som rödvin, katt- och hundurin, mjölk, kaffe och
          andra fläckar som fett och mat på djupet. Du kan försöka ta bort
          fläcken själv men vill du vara säker på ett bra resultat, hör du med
          oss först. Eller så lämnar du helt enkelt in mattan till oss för
          rengöring och fläckborttagning.
        </BodyTxt>

        <TwoColumns>
          <ImgDiv>
            <Img src={require("../../../assets/homeforeefter3.jpg")}></Img>
          </ImgDiv>
          <ImgDiv>
            <Img src={require("../../../assets/homeforeefter4.jpeg")}></Img>
          </ImgDiv>
        </TwoColumns>
      </Section>
      <Footer></Footer>
    </div>
  );
};

export default Tvätt;
