import React from "react";
import { slide as Menu } from "react-burger-menu";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { Colors, Sizes } from "../../Constants/Constants";
import "./styles.css";

const MenuText = styled.span`
  font-size: ${Sizes.XSmall};
  margin: ${Sizes.Small} ${Sizes.XXSmall};
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.3s;
  display: flex;
  
  }
`;

const BurgerMenu = (props) => {
  const isMobile = window.innerWidth < 700;

  return (
    <>
      {isMobile && (
        <Menu {...props}>
          <NavLink
            to="/"
            style={({ isActive }) =>
              isActive
                ? { color: `${Colors.LightBlue}` }
                : { color: `${Colors.Black}` }
            }
          >
            <MenuText>Hem</MenuText>
          </NavLink>
          <NavLink
            to="/tvatt"
            style={({ isActive }) =>
              isActive
                ? { color: `${Colors.LightBlue}` }
                : { color: `${Colors.Black}` }
            }
          >
            <MenuText>Tvätt</MenuText>
          </NavLink>
          <NavLink
            to="/lagning"
            style={({ isActive }) =>
              isActive
                ? { color: `${Colors.LightBlue}` }
                : { color: `${Colors.Black}` }
            }
          >
            <MenuText>Lagning</MenuText>
          </NavLink>
          <NavLink
            to="/portfölj"
            style={({ isActive }) =>
              isActive
                ? { color: `${Colors.LightBlue}` }
                : { color: `${Colors.Black}` }
            }
          >
            <MenuText>Portfölj</MenuText>
          </NavLink>

          <NavLink
            to="/kontakt"
            style={({ isActive }) =>
              isActive
                ? { color: `${Colors.LightBlue}` }
                : { color: `${Colors.Black}` }
            }
          >
            <MenuText>Kontakt</MenuText>
          </NavLink>
        </Menu>
      )}
    </>
  );
};
export default BurgerMenu;
