import TopContainer from "./Components/PAGES/Top/topContainer";
import Header from "./Components/PAGES/Header/header";
import { NavLink } from "react-router-dom";
import Footer from "./Components/PAGES/Footer/footer";
import "./index.css";
import styled from "styled-components";
import { Helmet } from "react-helmet";

const TopLine = styled.div`
  width: 100%;
  background-color: #888;
  height: 4px;
  @media (max-width: 768px) and (min-width: 300px) {
    height: 3px;
  }
`;
const TwoColumns = styled.div`
  width: 100%;

  height: auto;
  margin: 3rem 0rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) and (min-width: 300px) {
    flex-direction: column;
    margin: 1rem 0;
  }
  @media (max-width: 1100px) and (min-width: 769px) {
    margin: 1rem 0;
  }
`;
const TwoColumnsFirst = styled.div`
  width: 100%;

  height: auto;
  margin: 3rem 0rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) and (min-width: 300px) {
    flex-direction: column-reverse;
    margin: 1rem 0;
  }
  @media (max-width: 1100px) and (min-width: 769px) {
    margin: 1rem 0;
  }
`;

const TextDiv = styled.div`
  width: 50%;
  height: auto;
  padding: 0 8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: 768px) and (min-width: 300px) {
    padding: 0 1rem;
    width: 90%;
  }
  @media (max-width: 1100px) and (min-width: 769px) {
    padding: 0rem 1rem;

    width: 90%;
  }
`;
const TextDivLag = styled.div`
  width: 90%;
  height: auto;
  margin: 2rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) and (min-width: 300px) {
    padding: 0 1rem;
    width: 90%;
    margin: 2rem 0rem;
  }
  @media (max-width: 1100px) and (min-width: 769px) {
    padding: 0rem 1rem;

    width: 90%;
  }
`;
const TextDivBottom = styled.div`
  width: 50%;
  height: auto;
  padding: 0 8rem;
  display: flex;
  background-color: #fbfcfc;
  opacity: 0.6;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) and (min-width: 300px) {
    padding: 0rem 1rem;
    margin: 0.5rem 0;
    width: 90%;
  }
  @media (max-width: 1100px) and (min-width: 769px) {
    padding: 0rem 1rem;
    margin: 0.5rem 0;
    width: 90%;
  }
`;
const ImgDiv = styled.div`
  width: 50%;
  background-color: #fff;
  height: auto;
  padding: 2rem;
  align-items: center;
  @media (max-width: 768px) and (min-width: 300px) {
    width: 95%;
    padding: 0;
  }
  @media (max-width: 1100px) and (min-width: 769px) {
    width: 95%;
  }
`;
const Img = styled.img`
  width: 100%;
  height: auto;
  @media (max-width: 768px) and (min-width: 300px) {
  }
`;
const TitleTxt = styled.span`
  font-size: 1.7rem;
  text-align: left;
  font-weight: 900;
  line-height: 35px;
  letter-spacing: 0.05em;
  margin: 1rem 0;
  @media (max-width: 768px) and (min-width: 300px) {
    font-size: 1.3rem;
  }
  @media (max-width: 1100px) and (min-width: 769px) {
    font-size: 1.4rem;
  }
`;
const TitleArbete = styled.span`
  font-size: 1.45rem;
  text-align: left;
  margin: 1rem 0;
  font-weight: 900;
  opacity: 1;
  letter-spacing: 0.05em;
  @media (max-width: 768px) and (min-width: 300px) {
    text-align: center;
    font-size: 1.2rem;
    margin: 0;
  }
  @media (max-width: 1100px) and (min-width: 769px) {
    text-align: center;
    font-size: 1.25rem;
    margin: 0;
  }
`;
const BodyTxt = styled.span`
  font-size: 1.2rem;
  text-align: left;
  letter-spacing: 0.05em;
  line-height: 35px;
  @media (max-width: 768px) and (min-width: 300px) {
    font-size: 1rem;
  }
  @media (max-width: 1100px) and (min-width: 769px) {
    font-size: 1.1rem;
  }
`;
const BodyArbeteTxt = styled.span`
  font-size: 1.06rem;
  opacity: 1;
  text-align: left;
  letter-spacing: 0.05em;
  line-height: 35px;
  @media (max-width: 768px) and (min-width: 300px) {
    font-size: 1rem;
    margin: 0;
  }
  @media (max-width: 1100px) and (min-width: 769px) {
    font-size: 1.1rem;
    margin: 0;
  }
`;
const LagningRen = styled.div`
  width: 32%;
  background-color: #fff;
  height: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) and (min-width: 300px) {
    width: 90%;
  }
`;

const ForeImg = styled.img`
  width: 100%;
  height: auto;
  max-height: 15rem;
  min-height: 15rem;
  align-items: center;
  filter: brightness(1.1);
  box-shadow: 2px 2px 5px 2px #ccc;

  bottom: 0px;
  left: 0;
  @media (max-width: 768px) and (min-width: 300px) {
    margin-top: 1rem;
  }
`;

const TwoColumnsForeEfter = styled.div`
  width: 100%;

  height: auto;
  margin: 3rem 0rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  @media (max-width: 768px) and (min-width: 300px) {
    flex-direction: column;
    margin: 1rem 0;
    align-items: center;
  }
  @media (max-width: 1100px) and (min-width: 769px) {
    margin: 1rem 0;
  }
`;
const LagTitle = styled.h4`
  font-size: 1.5rem;
  text-align: left;
  font-weight: 900;
  letter-spacing: 0.05em;
  margin: 1rem 0;
  @media (max-width: 768px) and (min-width: 300px) {
    font-size: 1.1rem;
  }
`;
const LagTxt = styled.span`
  font-size: 1.1rem;
  letter-spacing: 0.05em;
  line-height: 22px;
  padding: 0 1rem;
  text-align: left;
  @media (max-width: 768px) and (min-width: 300px) {
    font-size: 1rem;
  }
  @media (max-width: 1100px) and (min-width: 769px) {
    font-size: 1.1rem;
  }
`;
const LagLink = styled.span`
  font-size: 1rem;
  color: blue;
  text-align: left;
  letter-spacing: 0.05em;
  @media (max-width: 768px) and (min-width: 300px) {
  }
`;

const HomePage = () => {
  return (
    <div>
      <Helmet
        title={
          "Mattservicen - mattävtt stockholm - mattlagning - mattrengörning - märta måås lagning - mattlängettering "
        }
      >
        <meta name="MattServicen" content="MattServicen" />
      </Helmet>
      <TopLine></TopLine>
      <TopContainer />
      <Header />
      <TwoColumnsFirst>
        <ImgDiv>
          <Img src={require("../src/assets/lagning04.jpg")}></Img>
        </ImgDiv>
        <TextDiv>
          <TitleTxt>
            Mattservicen är ett företag som är specialiserade på att sköta om
            mattor på bästa sätt: Lagning, uppfräschning, tvättning,
            fläckborttagning
          </TitleTxt>
          <BodyTxt>
            Vi arbetar med orientmattor och moderna mattor. Vi älskar vårt
            arbete och vårt mål att alltid ha nöjda kunder.
          </BodyTxt>
        </TextDiv>
      </TwoColumnsFirst>
      <TwoColumns>
        <TextDiv>
          <TitleTxt>
            Vi på Mattservicen tar hand om dina mattor. Skötsel, omvårdnad,
            rengöring och fläckborttagning
          </TitleTxt>
          <BodyTxt>
            Mattor som vi arbetar med är till exempel orientmattor, röllakan,
            rya- och ullmattor.
          </BodyTxt>
          <BodyTxt>
            Mattservicen arbetar med alla förekommande reparationer och
            lagningar av alla typer av mattor och textilier, såsom gobelänger
            och vepor. Vid restaurationsarbetet är det viktigt att material och
            färger överensstämmer med originalet. Det är bättre att ta tag i
            lagningen i tid. Om det går för långt blir kostnaden högre.
          </BodyTxt>
        </TextDiv>
        <ImgDiv>
          <Img src={require("../src/assets/mainPage1.jpg")}></Img>
        </ImgDiv>
      </TwoColumns>
      <TextDivLag>
        <TitleTxt>Lagning och rengöring av mattor</TitleTxt>
        <BodyTxt>
          Vi rengör dina mattor och tar bort fläckar. Tänk på att vissa trasiga
          mattor eller textilier i vissa fall måste lagas eller på annat sätt
          prepareras före en tvätt. Ett gott råd är att se till att tvätta
          mattan vid lätt nedsmutsning. Det kan slita på mattan att vänta för
          länge med rengöringen.
        </BodyTxt>
      </TextDivLag>

      <TwoColumnsForeEfter>
        <LagningRen>
          <BodyTxt>Före fläckborttagning</BodyTxt>
          <ForeImg src={require("../src/assets/homefläkt03.JPG")}></ForeImg>

          <BodyTxt>Efter fläckborttagning</BodyTxt>
          <ForeImg src={require("../src/assets/homefläkt04.JPG")}></ForeImg>

          <LagTitle>Service</LagTitle>
          <LagTxt>
            Vi inte bara regör och lagar dina mattor, våra professionella
            kunskaper gör att vi håller mycket hög kvalitet på arbetet.
          </LagTxt>
        </LagningRen>

        <LagningRen>
          <BodyTxt>Före lagning</BodyTxt>
          <ForeImg src={require("../src/assets/tradition01.jpg")}></ForeImg>

          <BodyTxt>Efter lagning</BodyTxt>
          <ForeImg src={require("../src/assets/tradition.jpg")}></ForeImg>

          <LagTitle>Förtroende</LagTitle>
          <LagTxt>
            Vårt arbete utförs helt för hand och vår personal garanterar ett
            fullgott utfört arbete. Ett gott betyg är att våra kunder
            återkommer.
          </LagTxt>
        </LagningRen>
        <LagningRen>
          <BodyTxt>Innan tvätt och lagning av fransarna</BodyTxt>
          <ForeImg src={require("../src/assets/home10.jpg")}></ForeImg>

          <BodyTxt>Efter tvätt och lagning av fransarna</BodyTxt>
          <ForeImg src={require("../src/assets/home11.jpg")}></ForeImg>

          <LagTitle>Tradition</LagTitle>
          <LagTxt>
            Vi har under många år arbetat med det som vi helst vill göra. Vi har
            generationer av tradition att falla tillbaka.
          </LagTxt>
        </LagningRen>
      </TwoColumnsForeEfter>
      <NavLink to="/lagning">
        <LagLink>Se vidare vår service</LagLink>
      </NavLink>

      <TwoColumns>
        <ImgDiv>
          <Img src={require("../src/assets/lagning01.jpg")}></Img>
        </ImgDiv>
        <TextDiv>
          <TitleTxt>
            Inget arbete är för svårt för oss! Vi erbjuder fullservice.
          </TitleTxt>
          <BodyTxt>
            Det kan låta pretentiöst men det arbete vi lovar att utföra, det gör
            vi också. Mattorna kan vara moderna eller antika, vi behandlar dem
            på bästa sätt. Mattor ska inte bara användas - de ska ge betraktaren
            och användaren en skönhetsupplevelse.
          </BodyTxt>
        </TextDiv>
      </TwoColumns>
      <TwoColumns>
        <TextDiv>
          <NavLink to="/kontakt">
            <TitleArbete>Boka regelbundet</TitleArbete>
          </NavLink>

          <BodyArbeteTxt>
            Vi åtar oss en regelbunden service av din matta
          </BodyArbeteTxt>
        </TextDiv>
        <TextDiv>
          <NavLink to="/kontakt">
            <TitleArbete>Rena mattor</TitleArbete>
          </NavLink>
          <BodyArbeteTxt>
            Vi tvättar alla sorters mattor och textilier.
          </BodyArbeteTxt>
        </TextDiv>
      </TwoColumns>
      <TwoColumns>
        <TextDiv>
          <NavLink to="/kontakt">
            <TitleArbete>Akut hjälp</TitleArbete>
          </NavLink>
          <BodyArbeteTxt>
            Ibland händer det olyckor. Ring oss så tar vi hand om det
            omedelbart.
          </BodyArbeteTxt>
        </TextDiv>
        <TextDiv>
          <NavLink to="/kontakt">
            <TitleArbete>Kostnadsförslag</TitleArbete>
          </NavLink>
          <BodyArbeteTxt>Kontakta oss för offert.</BodyArbeteTxt>
        </TextDiv>
      </TwoColumns>
      <div>
        <TwoColumns>
          <TextDivBottom>
            <BodyArbeteTxt>Vi tvättar alla mattor</BodyArbeteTxt>
            <TitleArbete>MATTVÄTT</TitleArbete>
          </TextDivBottom>
          <TextDivBottom>
            <BodyArbeteTxt>Mattan blir som ny</BodyArbeteTxt>
            <TitleArbete>REPARATIONER</TitleArbete>
          </TextDivBottom>
          <TextDivBottom>
            <BodyArbeteTxt>Större mattor hämtas</BodyArbeteTxt>
            <TitleArbete>HÄMTNING</TitleArbete>
          </TextDivBottom>
        </TwoColumns>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default HomePage;
